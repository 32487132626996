<template lang="pug">
    v-parallax#intro(
        src="../assets/fast-coding-ARSZF5G.jpg"
        height="800"
    )
        v-row
            v-col.col-9.md-5.text-center.pa-md-16.pt-16.pa-8.ma-md-16.mt-16(align-self="start")
                h1.text-md-h1.text-h4.mb-4.font-weight-thin Tap + Spigot
                h2.text-md-h5.text-subtitle-2 Web Design and Development
            v-spacer
</template>

<script>
export default {
    name: 'Intro',
}
</script>

<style>

</style>
