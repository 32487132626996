<template lang="pug">
	v-container
		v-row.align-center
			v-col.col-md-7.col-lg-8.pr-md-6.align-center
				v-img(
				:src="require('../assets/developing-programming-and-coding-technologies-2021-04-05-21-01-51-utc.jpg')"
				max-height="800"
				)
			v-col.col-md-5.col-lg-4
				v-row.text-center
					v-col.col-12.mb-4
						v-icon(
							x-large
							color="secondary"
						) mdi-code-tags
					v-col.col-12
						h3.text-h5.text-md-subtitle-1 Need a site for your organization?
					v-col.col-12
						h3.text-h5.text-md-subtitle-1  Does your current site need a facelift?
					v-col.col-12
						h3.text-h5.text-md-subtitle-1 Find it hard to keep the content current?
					v-col.col-12
						h3.text-h5.text-md-subtitle-1 Need your site to do something specific?
					v-col.col-12.my-4
						h1.text-h4 We are here to help!
					v-col.col-12
						v-btn(
							floating
							flex
							color="secondary"
							to="#services"
						) Check out our Services
					v-col.col-12.mt-4
						v-icon(
							x-large
							color="secondary"
						) mdi-xml

</template>

<script>

export default {
    name: 'Intro',
}
</script>
