import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './views/Home.vue'
// import Services from './components/Services'
// import About from './components/About'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            if (to.hash === '#home') {
                return { x: 0, y: 0, behavior: 'smooth' }
            }
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    },
})

export default router
