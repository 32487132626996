<template lang="pug">
	v-container
		v-row.align-center
			v-col.col-12.col-md-4
				v-img(
					contain
					:src="require('../assets/matt_profile.png')"
					height="400"
				)
			v-col.col-12.col-md-8
				h2 Company Bio
				p Tap + Spigot was created to help those individuals who want to leverage technology, but are unsure how to do so.  We work with any size organization, but are committed to helping small businesses create and maintain a website they can be proud of.
				p We enjoy creating new or updating existing websites to help you get the most out of the digital world. We love to help organizations freshen up or fix outdated/broken sites.
				h2 About the Founder
				p I started my journey as a developer 9 years ago just playing around with it for fun and teaching myself. Seven years later after dabbling here and there and gaining a variety of experience working for a couple companies on projects as an employee and freelancer I decided to do what I love working for myself.
				h3.text-h5.text-center.py-6 Want a functioning website you can be proud of?
				h3.text-h4.text-md-h5.text-center We would love to hear from you!
</template>

<script>
export default {
    name: 'About',
}
</script>

<style>

</style>
