<template lang="pug">
	v-container
		v-row
			v-col.col-12.col-md-4.py-12.py-md-0
				v-row.text-center
					v-col.col-12.pb-16
						h1 Services
				v-row.text-center
					v-col.col-4
						v-icon(x-large color="primary") mdi-pencil-ruler
						p.text-caption website design
					v-col.col-4
						v-icon(x-large color="primary") mdi-xml
						p.text-caption website development
					v-col.col-4
						v-icon(x-large color="primary") mdi-tools
						p.text-caption website maintenance
				v-row.text-center
					v-col.col-6
						v-icon(x-large color="primary") mdi-domain
						p.text-caption Domain and hosting setup
					v-col.col-6
						v-icon(x-large color="primary") mdi-database-settings
						p.text-caption database management
				v-row.text-center
						v-col.col-4
							v-icon(x-large color="primary") mdi-application
							p.text-caption custom apps
						v-col.col-4
							v-icon(x-large color="primary") mdi-gauge
							p.text-caption content management solutions
						v-col.col-4
							v-icon(x-large color="primary") mdi-currency-usd
							p.text-caption e-commerce
			v-col.col-md-1.text-center
				v-divider(vertical)
			v-col.col-12.col-md-7
				v-row.text-center
					v-col.col-12
						h1 Pricing Packages
				v-row
					v-spacer
					v-col.col-1
						v-icon(color="primary") mdi-code-equal
					v-col.col-11
						h3 Monthly Maintenance Bundles
						p These bundles are for work to be performed on already established sites.
						v-row(
							v-for="bundle in bundles"
							key="bundle.label"
							dense
						)
							v-col.col-5.col-sm-3
								h4 {{ bundle.label }}:
							v-col.col-5.col-sm-7
								p {{ bundle.description }}
							v-col.col-2
								p ${{ bundle.price }}
						p.text-caption *unused hours (up to 25% of the amount purchased) can be rolled over to the next month (i.e. Purchased monthly 10 includes 10 hours, 2.5 hours can be rolled over to be used the immediate following month).
				v-row
					v-spacer
					v-col.col-1
						v-icon(color="primary") mdi-code-equal
					v-col.col-11
						h3 Design/Development of a new custom site or app
						p Contact us to request a quote
				v-row.text-center
					v-col.col-12
						v-btn(
							floating
							flex
							color="secondary"
							to="#contact"
						) Contact Us

</template>

<script>
export default {
    name: 'Services',

    data() {
        return {
            bundles: [
                // {
                //     label: 'MONTHLY 5',
                //     description: 'covers up to 5 hours of work for the month',
                //     price: '235',
                // },
                // {
                //     label: 'MONTHLY 10',
                //     description: 'covers up to 10 hours of work for the month',
                //     price: '450',
                // },
                // {
                //     label: 'MONTHLY 20',
                //     description: 'covers up to 20 hours of work for the month',
                //     price: '800',
                // },
            ],
        }
    },
}
</script>

<style>

</style>
