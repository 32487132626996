<template lang="pug">
    v-container
        v-row
            v-col.col-12.col-md-6
                v-row
                    v-col.col-12.col-sm-6.col-md-12
                        v-row
                            v-col.col-12.text-center.px-4.px-md-0
                                h2 Curious about the company name and logo?
                            v-col.col-12.text-center.px-4.px-md-0
                                h2 I meshed my love for coding and beer together!
                    v-col.col-12.col-sm-6.col-md-12.text-center(style="max-height: 200px;")
                        v-img(
                            contain,
                            src="../assets/company_logo_website_logo_transparent_background.png",
                            max-height="100%",
                            max-width="100%"
                        )
            v-spacer
            v-col.col-12.col-md-5.pt-8.pt-md-0
                v-row.text-center
                    v-col.col-12
                        h1 Contact Us
                v-row
                    v-col.col-1
                        a(href="tel:207-558-5906" style="text-decoration: none;")
                            v-icon(color="primary") mdi-phone
                    v-col.col-11
                        a(href="tel:207-558-5906") 207-558-5906
                v-row
                    v-col.col-1
                        a(href="mailto:matt@tapandspigot.com?subject=Inquiry%20from%20Tap%20+%20Spigot" style="text-decoration: none;")
                            v-icon(color="primary") mdi-email
                    v-col.col-11
                        a(href="mailto:matt@tapandspigot.com?subject=Inquiry%20from%20Tap%20+%20Spigot") matt@tapandspigot.com
                v-row
                    v-col.col-1
                        a(href="https://goo.gl/maps/nL9KAnTpm5H85iTp8" target="_blank" style="text-decoration: none;")
                            v-icon(color="primary") mdi-map-marker
                    v-col.col-11
                        a(href="https://goo.gl/maps/nL9KAnTpm5H85iTp8" target="_blank") Gorham, ME
</template>

<script>
export default {
    name: 'Contact',
}
</script>

<style>

</style>
