<template lang="pug">
    v-app
        v-navigation-drawer(v-model="drawer", fixed, bottom, temporary)
            v-list(nav)
                v-list-item-group(
                    v-model="group",
                    active-class="primary--text text--accent-4"
                )
                    v-list-item(
                        v-for="link in links",
                        :key="`${link.label}-drawer-link`",
                        text,
                        rounded,
                        :to="link.url"
                    )
                        v-list-item-title {{ link.label }}
        v-app-bar(
            app,
            color="primary",
            dark,
            dense,
            elevate-on-scroll,
        )
            v-app-bar-nav-icon(@click.stop="drawer = !drawer")

            v-img(
                contain,
                src="./core/assets/company_logo_website_logo_transparent_background.png",
                max-height="40",
                max-width="100"
            )

            v-spacer

            v-btn.d-none.d-sm-flex(
                v-for="link in links",
                :key="`${link.label}-header-link`",
                text,
                rounded,
                :to="link.url"
            ) {{ link.label }}

        v-main.align-center
            Intro
            v-container(fluid)
                v-row.fill-height
                    v-col
                        router-view

        v-footer(app, padless, color="primary", dark)
            v-col.text-center(cols="12") {{ new Date().getFullYear() }} — <strong>Tap & Spigot</strong>
</template>

<script>
import Intro from './core/components/Intro.vue'

export default {
    name: 'App',

    components: {
        Intro,
    },

    data() {
        return {
            links: [
                {
                    label: 'Home',
                    url: '#home',
                },
                {
                    label: 'Services',
                    url: '#services',
                },
                {
                    label: 'About',
                    url: '#about',
                },
                {
                    label: 'Contact',
                    url: '#contact',
                },
            ],
            drawer: false,
            group: null,
        }
    },

    watch: {
        group() {
            this.drawer = false
        },
    },
}
</script>
