<template lang="pug">
    v-container
        intro-2#intro2.py-8.py-md-16.my-8.my-md-16
        services#services.py-8.py-md-16.my-8.my-md-16
        about#about.py-8.py-md-16.my-8.my-md-16
        contact#contact.py-8.py-md-16
</template>

<script>
import Intro2 from '../components/Intro2'
import Services from '../components/Services'
import About from '../components/About'
import Contact from '../components/Contact.vue'

export default {
    name: 'Home',

    components: {
        Intro2,
        Services,
        About,
        Contact,
    },
}
</script>
